import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


const Gallery = () => {
   const [data, setData] = useState([]);
  let [newArr, setNewArr] = useState([]);
  let [arr, setArr] = useState([]);
  
  useEffect(() => {
    fetch("https://api.tvmaze.com/search/shows?q=all")
      .then((response) => response.json())
      .then((dd) => {
        for (var i = 0; i < dd.length; i++) {
          if (dd[i].show.image !== null) {
            newArr.push(dd[i]);
          }
        }
         setData(dd);
        //  console.log(newArr.map((e)=>e));
        arr = newArr.filter(function (e) {
          return dd.indexOf(e) > -1;
        });
        setData(arr);
        console.log(arr.map((e) => e.show.image.medium));
      });
  }, []);
  console.log(data);

  return (
    <>
      <Row xs={1} my={4} className="g-4">
        {data.map((e) => (
          <Col md={4}>
            <Card>
              <Card.Body>
                <main id="main" data-aos="fade" data-aos-delay="1500">
                  <section id="gallery" className="gallery">
                    <div className="container-fluid"></div>
                    <div className="gallery-item h-100">
                      <Card.Img
                        variant="top"
                        height={"400px"}
                        src={e.show.image.medium}
                      />
                      <div className="gallery-links  align-items-center justify-content-center">                        
                        <a href={e.show.officialSite} className="details-link">
                          <Card.Title style={{fontSize:'3rem'}}>{e.show.name}</Card.Title>
                        </a>
                        <a href={e.show.officialSite} className="details-link">
                          <i className="bi bi-link-45deg">
                            {e.show.rating.average}
                          </i>
                        </a>
                        <br></br>
                        <label>Language : </label>
                        <label>{e.show.language}</label>
                        <br></br>
                        <label>Genres : </label>
                        <label>{e.show.genres[0]}</label>
                        <br></br>
                        <label>Duration : </label>
                        <label>{e.show.averageRuntime}</label>
                        <label>Minutes</label>
                        <br></br>
                        <label>Release date : </label>
                        <label>{e.show.premiered}</label>
                      </div>
                    </div>
                  </section>
                </main>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Gallery;
