import './App.css';
import React from 'react';
import './assets/css/main.css';
import Gallery from './Gallery';

function App() { 

  return (
    <div className="App">      
        <i className="bi bi-camera"></i>
        <h1>American Shows</h1>
        <Gallery />
      </div>
  );
}

export default App;
